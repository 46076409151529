import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Loading from '@zd/components/loading';

import theme from '@zd/theme';

const ButtonElement = styled('button', {
  shouldForwardProp: prop => prop !== 'color',
})`
  border: none;
  background: transparent;
  padding: 1rem 1.25rem;
  color: ${theme.colors.primaryBlue};
  min-width: 150px;
  transition: all 0.1s ease-in;
  border-radius: 25.5px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;

  ${({ color }) => {
    if (color === 'primary') {
      return css`
        background: linear-gradient(139.6deg, #9257de 0%, #5a2cba 99.11%, #592cba 100%);
        box-shadow: 0 4px 10px 0 rgba(81, 0, 255, 0.2);
        color: #f3f3f3;
      `;
    }
    return css`
      background: linear-gradient(141.26deg, #ffe8d0 0%, #f8dbbe 100%);
      box-shadow: 0 4px 10px 0 rgba(255, 128, 0, 0.13);
      color: ${theme.colors.primaryBlue};
    `;
  }}
`;

const Button = props => {
  const { children, color = 'primary', href, active, disabled, loading, onClick, ...rest } = props;

  return (
    <ButtonElement
      onClick={onClick}
      active={active}
      disabled={loading || disabled}
      href={href}
      color={color}
      {...rest}
    >
      {loading ? <Loading /> : children}
    </ButtonElement>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  color: PropTypes.string,
  href: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  active: false,
  disabled: false,
  loading: false,
};

export default Button;
