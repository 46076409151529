import React from 'react';
import PropTypes from 'prop-types';
import {
  FaLinkedin as LinkedInIcon,
  FaGooglePlay as GooglePlayIcon,
  FaTwitterSquare as TwitterIcon,
  FaFacebookSquare as FacebookIcon,
  FaPinterest as PinterestIcon,
  FaFacebookMessenger as MessengerIcon,
} from 'react-icons/fa';
import {
  GrYoutube as YouTubeIcon,
  GrInstagram as InstagramIcon,
  GrCheckbox as BoxIcon,
  GrSpotify as SpotifyIcon,
} from 'react-icons/gr';
import { ImWhatsapp as WhatsAppIcon } from 'react-icons/im';
import { AiFillGithub as GitHubIcon } from 'react-icons/ai';
import styled from '@emotion/styled';

import Link from '@zd/components/link';
import theme from '@zd/theme';

const SocialStyledLink = styled(Link)`
  color: ${theme.colors.primaryBlue};
  text-decoration: none;
  transition: all 0.1s ease-in-out;

  &:hover {
  }

  svg {
    width: 30px;
    height: 30px;
  }
`;

const SocialIcon = props => {
  const { title, url, ...rest } = props;

  let Icon = BoxIcon;

  switch (title) {
    case 'LinkedIn':
      Icon = LinkedInIcon;
      break;
    case 'Google Play':
      Icon = GooglePlayIcon;
      break;
    case 'Twitter':
      Icon = TwitterIcon;
      break;
    case 'GitHub':
      Icon = GitHubIcon;
      break;
    case 'YouTube':
      Icon = YouTubeIcon;
      break;
    case 'Facebook':
      Icon = FacebookIcon;
      break;
    case 'Instagram':
      Icon = InstagramIcon;
      break;
    case 'Pinterest':
      Icon = PinterestIcon;
      break;
    case 'Messenger':
      Icon = MessengerIcon;
      break;
    case 'Spotify':
      Icon = SpotifyIcon;
      break;
    case 'WhatsApp':
      Icon = WhatsAppIcon;
      break;
    default:
      Icon = BoxIcon;
  }

  return (
    <SocialStyledLink
      href={url}
      target="_blank"
      keep
      title={`Clínica da Boavista no ${title}`}
      {...rest}
    >
      <Icon />
    </SocialStyledLink>
  );
};

SocialIcon.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};

export default SocialIcon;
