export const orange = '#ff7332';
export const lightGrey = '#f8f9fa';
export const darkGrey = '#4d4e56';

// clinica

export const black = '#000000';
export const white = '#ffffff';

export const lightBrown = '#FDF8F4';

export const primaryBlue = '#3D1E80';

export const secondaryBlue = '#51FFDB';

export const itemBorder = '#e3e3e3';

export const mainGrey = '#706782';
