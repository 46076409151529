/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis
 */

export { default as wrapRootElement } from './src/redux/wrapper';

require('bootstrap/dist/css/bootstrap.min.css');
require('prismjs/themes/prism-solarizedlight.css');
