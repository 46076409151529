import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { FiMenu as OpenIcon } from 'react-icons/fi';
import { AiOutlineClose as CloseIcon } from 'react-icons/ai';

import { NAVBAR_MOBILE_LINKS } from '@zd/config/links';
import useSiteMetadata from '@zd/hooks/useSiteMetadata';
import SocialIcon from '@zd/components/social-icon';
import links from '@zd/config/links';
import { Container } from '@zd/components/grid';
import Link from '@zd/components/link';
import theme from '@zd/theme';

const Nav = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  height: ${theme.variables.navbarMobileHeight};
  display: flex;
  align-items: center;

  font-size: 1rem;
  font-weight: 400;
  z-index: ${theme.layers.navbar};
  background: ${theme.colors.lightBrown};
  border-bottom: 1px solid ${theme.colors.itemBorder};
`;

const TopDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const LogoStyledLink = styled(Link)`
  display: block;

  & > img {
    width: 200px;
    padding-left: 15px;
  }
`;

const MenuButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  color: ${theme.colors.primaryBlue};
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  height: 100%;
  min-width: ${theme.variables.navbarMobileHeight};

  &:focus {
    outline: none;
  }
`;

const CollapseDiv = styled.div`
  position: fixed;
  top: ${theme.variables.navbarMobileHeight};
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100% - ${theme.variables.navbarMobileHeight});
  background: ${theme.colors.lightBrown};
  overflow-y: auto;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Ul = styled.ul`
  > li {
    border-bottom: 1px solid ${theme.colors.itemBorder};
    margin-bottom: 0;
  }
`;

const StyledLink = styled(Link, {
  shouldForwardProp: prop => prop !== 'active',
})`
  display: flex;
  justify-content: space-between;
  padding: 1rem 15px;
  color: ${theme.colors.primaryBlue};
  font-weight: 500;

  &:hover {
    color: ${theme.colors.primaryBlue};
  }

  &:hover:after {
    width: 100%;
  }

  ${({ active }) =>
    css`
      color: ${active ? theme.colors.primaryBlue : theme.colors.primaryBlue};
      background: ${active ? theme.colors.lightBrown : theme.colors.lightBrown};
    `}
`;

const BottomDiv = styled.div`
  padding: 15px;
  margin-top: 1rem;
`;

const SocialUl = styled.ul`
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;

  & > li {
    margin-bottom: 0;
  }

  & > li:not(:last-of-type) {
    margin-right: 0.75rem;
  }
`;

const CopyrightP = styled.p`
  width: 100%;
  color: darkgrey;
  text-align: left;
`;

const Navbar = props => {
  const [open, setOpen] = useState(false);

  const siteMetadata = useSiteMetadata();

  useEffect(() => {
    if (open) {
      const { scrollY } = window;
      document.body.style.position = 'fixed';
      document.body.style.top = `${scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY?.split('px')?.[0] || '0', 10));
    }
  }, [open]);

  return (
    <Nav {...props}>
      <TopDiv>
        <LogoStyledLink href="/" onClick={() => setOpen(false)}>
          <img src="/images/logo.svg" alt={siteMetadata.title} />
        </LogoStyledLink>
        <MenuButton onClick={() => setOpen(!open)}>{open ? '✕' : <OpenIcon />}</MenuButton>
      </TopDiv>
      {open && (
        <CollapseDiv>
          <FlexDiv>
            <Ul>
              {NAVBAR_MOBILE_LINKS.map(link => (
                <li key={link.href}>
                  <StyledLink href={link.href} onClick={() => setOpen(false)}>
                    <span>{link.title}</span>
                    <span>&rsaquo;</span>
                  </StyledLink>
                </li>
              ))}
            </Ul>
            <BottomDiv>
              <SocialUl>
                {siteMetadata.socialLinks.map(link => (
                  <li key={link.title}>
                    <SocialIcon title={link.title} url={link.url} />
                  </li>
                ))}
              </SocialUl>
              <CopyrightP>
                {siteMetadata.title} © {new Date().getFullYear()}
                <br />
                Todos os Direitos Reservados
              </CopyrightP>
            </BottomDiv>
          </FlexDiv>
        </CollapseDiv>
      )}
    </Nav>
  );
};

export default Navbar;
