import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import {
  get as getUserAcceptsCookies,
  set as setUserAcceptsCookies,
} from '@zd/cookies/userAcceptsCookies';
import Button from '@zd/components/button';
import Link from '@zd/components/link';
import theme from '@zd/theme';

const WrapperDiv = styled.div`
  color: ${theme.colors.black};
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  padding: 1.25rem;
  background: ${theme.colors.lightBrown};
  border: 1px solid ${theme.colors.primaryBlue};
  border-radius: 0.75rem;
  box-shadow: 0 4px 23px 0 rgba(0, 0, 0, 0.09);
  max-width: 900px;
  z-index: ${theme.layers.cookieBanner};
  animation: ${theme.keyframes.slide} 0.4s ease-in-out forwards;

  ${theme.medias.gteLarge} {
    padding: 1.5rem;
    width: 900px;
    max-width: 70vw;
    bottom: 2rem;
    left: 2rem;
    right: auto;
  }

  p {
    line-height: 1.35;
    margin-bottom: 0.75rem;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    color: ${theme.colors.mainGrey};

    ${theme.medias.gteLarge} {
      font-size: 16px;
      text-align: justify;
    }
  }
`;

const CloseButton = styled(Button)``;

const CookieBanner = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setUserAcceptsCookies('true');
  };

  useEffect(() => {
    setTimeout(() => {
      const shouldShow = !getUserAcceptsCookies();

      if (shouldShow) {
        setIsOpen(true);
      }
    }, 1000);
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <WrapperDiv>
      <div>
        <p>
          Este site utiliza cookies próprias e de terceiros para fornecer uma melhor experiência ao
          utilizador. Ao usar este site, você aceita o uso de cookies. Para mais detalhes consulte
          nossa{' '}
          <Link href="/legal/politica-de-privacidade" title="Política de Privacidade">
            política de privacidade
          </Link>
          .
        </p>
        <CloseButton onClick={handleClose} color="primary">
          Compreendi
        </CloseButton>
      </div>
    </WrapperDiv>
  );
};

export default CookieBanner;
