import React from 'react';
import styled from '@emotion/styled';

import { NAVBAR_DESKTOP_LINKS } from '@zd/config/links';
import useSiteMetadata from '@zd/hooks/useSiteMetadata';
import { Container } from '@zd/components/grid';
import Link from '@zd/components/link';
import useLocation from '@zd/hooks/useLocation';
import SuperLink from '@zd/components/super-link';
import SuperLinkDropdown from '@zd/components/super-link-dropdown';
import Button from '@zd/components/button';

import theme from '@zd/theme';

const Nav = styled.nav`
  height: ${theme.variables.navbarDesktopHeight};
  color: ${theme.colors.primaryBlue};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.itemBorder};
  position: fixed;
  top: 0;
  background: ${theme.colors.lightBrown};
  z-index: ${theme.layers.navbar};
  width: 100%;
  left: 0;
  right: 0;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
  }
`;

const StyledHomeLink = styled(Link)`
  > img {
    width: 200px;
  }
`;

const LinksUl = styled.ul`
  display: flex;
  align-items: center;

  > li {
    margin-bottom: 0;
    margin-right: 1rem;
  }
`;

const BookingLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: calc(${theme.variables.navbarDesktopHeight} - 20px);
  border: none;
  min-width: 150px;
  transition: all 0.1s ease-in;
  border-radius: 25.5px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  background: linear-gradient(139.6deg, #9257de 0%, #5a2cba 99.11%, #592cba 100%);
  box-shadow: 0 2px 5px 0 rgba(81, 0, 255, 0.2);
  color: #f3f3f3;

  &:hover {
    color: #f3f3f3;
  }
`;

const Navbar = props => {
  const siteMetadata = useSiteMetadata();
  const location = useLocation();

  return (
    <Nav {...props}>
      <StyledContainer>
        <StyledHomeLink href="/" title={siteMetadata.title}>
          <img src="/images/logo.svg" alt={siteMetadata.title} title={siteMetadata.title} />
        </StyledHomeLink>
        <div>
          <LinksUl>
            {NAVBAR_DESKTOP_LINKS.map((link, i) => {
              return (
                <li key={i}>
                  <SuperLink
                    href={link.href}
                    onClick={() => document.activeElement.blur()}
                    title={link.title}
                    active={
                      location.pathname.endsWith('/') && location.pathname.length > 1
                        ? location.pathname === `${link.href}/`
                        : location.pathname === link.href
                    }
                  >
                    {link.title}
                  </SuperLink>
                </li>
              );
            })}
          </LinksUl>
          <BookingLink href="/marcar-consulta" title="Marcar Consulta">
            Marcar Consulta
          </BookingLink>
        </div>
      </StyledContainer>
    </Nav>
  );
};

export default Navbar;
