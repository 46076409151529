import { useStaticQuery, graphql } from 'gatsby';

export default () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          slogan
          description
          siteUrl
          icon
          thumbnail
          author
          language
          country
          city
          address
          postalCode
          email
          phone
          keywords
          recaptcha {
            siteKey
            secretKey
          }
          themeColor
          backgroundColor
          socialUsernames {
            facebook
            instagram
            linkedin
          }
          socialLinks {
            title
            url
          }
        }
      }
    }
  `);

  return data.site.siteMetadata;
};
