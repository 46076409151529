import React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/core';
import styled from '@emotion/styled';

import globalStyles from '@zd/theme/styles';
import theme from '@zd/theme';

const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: ${theme.colors.lightBrown};
`;

const EmptyLayout = props => {
  const { children } = props;

  return (
    <>
      <Global styles={globalStyles} />
      <Main>{children}</Main>
    </>
  );
};

EmptyLayout.propTypes = {
  children: PropTypes.any,
};

export default EmptyLayout;
