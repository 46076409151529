export const NAVBAR_DESKTOP_LINKS = [
  {
    title: 'Sobre',
    href: '/sobre',
  },
  {
    title: 'FAQ',
    href: '/perguntas-frequentes',
  },
  {
    title: 'Blog',
    href: '/blog',
  },
  {
    title: 'Contactos',
    href: '/contactos',
  },
];

export const NAVBAR_MOBILE_LINKS = [
  {
    title: 'Início',
    href: '/',
  },
  {
    title: 'Sobre',
    href: '/sobre',
  },
  {
    title: 'Blog',
    href: '/blog',
  },
  {
    title: 'FAQ',
    href: '/perguntas-frequentes',
  },
  {
    title: 'Newsletter',
    href: '/newsletter',
  },
  {
    title: 'Contactos',
    href: '/contactos',
  },
  {
    title: 'Marcar Consulta',
    href: '/marcar-consulta',
  },
];

export const FOOTER_MAIN_LINKS = [
  {
    title: 'Sobre',
    href: '/sobre',
  },
  {
    title: 'Blog',
    href: '/blog',
  },
  {
    title: 'Contactos',
    href: '/contactos',
  },
  {
    title: 'Marcar Consulta',
    href: '/marcar-consulta',
  },
];

export const FOOTER_LEGAL_LINKS = [
  {
    tooltip: 'Perguntas Frequentes',
    title: 'FAQ',
    href: '/perguntas-frequentes',
  },
  {
    tooltip: 'Newsletter',
    title: 'Newsletter',
    href: '/newsletter',
  },
  {
    tooltip: 'Política de Privacidade',
    title: 'Privacidade',
    href: '/legal/politica-de-privacidade',
  },
  {
    tooltip: 'Política de Cookies',
    title: 'Cookies',
    href: '/legal/politica-de-cookies',
  },
];
