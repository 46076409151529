import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Link from '@zd/components/link';

import theme from '@zd/theme';

const marginBottom = '3px';

const StyledLink = styled(Link, {
  shouldForwardProp: prop => prop !== 'active',
})`
  position: relative;
  text-decoration: none;
  padding-bottom: ${marginBottom};
  margin-bottom: -${marginBottom};
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: ${theme.colors.primaryBlue};
    background-color: transparent;
    border-color: transparent;
    box-shadow: none !important;
    outline: none;

    &::before {
      width: 100%;
    }
  }

  &::before {
    position: absolute;
    content: '';
    background: ${theme.colors.primaryBlue};
    height: 2px;
    width: 0%;
    bottom: 0;
    border-radius: 2px;
    transition: all 0.27s;
  }

  ${({ active }) => {
    if (active) {
      return css`
        color: ${theme.colors.primaryBlue};

        &::before {
          width: 100%;
        }
      `;
    }
  }}
`;

const SupperLink = props => {
  const { children = false, passedRef, ...rest } = props;

  return (
    <StyledLink {...rest} passedRef={passedRef}>
      {children}
    </StyledLink>
  );
};

SupperLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  passedRef: PropTypes.any,
  active: PropTypes.bool,
};

SupperLink.defaultProps = {
  active: false,
};

export default SupperLink;
