import React from 'react';

import styled from '@emotion/styled';

import { FOOTER_MAIN_LINKS, FOOTER_LEGAL_LINKS } from '@zd/config/links';
import useLocation from '@zd/hooks/useLocation';
import useSiteMetadata from '@zd/hooks/useSiteMetadata';
import { Container } from '@zd/components/grid';
import Link from '@zd/components/link';
import SuperLink from '@zd/components/super-link';
import SocialIcon from '@zd/components/social-icon';
import theme from '@zd/theme';

const FooterElement = styled.footer`
  border-top: 1px solid ${theme.colors.itemBorder};
  color: ${theme.colors.primaryBlue};
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  ${theme.medias.gteLarge} {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
`;

const StyledContainer = styled(Container)`
  display: block;
  text-align: center;

  ${theme.medias.gteLarge} {
    text-align: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledHomeLink = styled(Link)`
  margin: 0 auto;
  margin-bottom: 1rem;

  ${theme.medias.gteLarge} {
    margin: initial;
    margin-bottom: 0;
  }
`;

const LinksUl = styled.ul`
  width: 100%;

  & > li {
    text-align: center;
    margin-bottom: 0.5rem;
  }

  ${theme.medias.gteLarge} {
    display: flex;
    align-items: center;
    width: auto;

    & > li {
      text-align: left;
      margin-bottom: 0;
      margin-right: 1rem;
    }

    & > li:last-of-type {
      margin-right: 0;
    }
  }
`;

const CopyrightP = styled.p`
  width: 100%;
  color: darkgrey;
  text-align: center;
  font-size: 0.95rem;

  ${theme.medias.gteLarge} {
    text-align: left;
  }
`;

const LegalUl = styled.ul`
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  margin: 1rem auto 2rem;

  ${theme.medias.gteLarge} {
    justify-content: flex-start;
    margin-top: 0.75rem;
    margin-bottom: 0;
    max-width: auto;
  }

  & > li {
    margin-bottom: 0;
    font-size: 0.9rem;

    &:not(:last-of-type) {
      &:after {
        ${theme.medias.gteLarge} {
          content: '•';
          margin: 0 0.5rem;
        }
      }
    }
  }
`;

const SocialUl = styled.ul`
  display: flex;
  justify-content: center;

  ${theme.medias.gteLarge} {
    justify-content: flex-end;
  }

  & > li {
    margin-bottom: 0;
  }

  & > li:not(:first-of-type) {
    margin-left: 0.75rem;
  }
`;

const PaymentsImg = styled.img`
  margin-bottom: 1.5rem;
  width: 200px;
  max-width: 100%;
`;

const DesktopFooter = props => {
  const siteMetadata = useSiteMetadata();
  const location = useLocation();

  return (
    <FooterElement {...props}>
      <StyledContainer>
        <StyledHomeLink href="/" title={siteMetadata.title}>
          <img src="/images/logo.svg" alt={siteMetadata.title} title={siteMetadata.title} />
        </StyledHomeLink>
        <LinksUl>
          {FOOTER_MAIN_LINKS.map((link, i) => (
            <li key={i}>
              <SuperLink
                href={link.href}
                onClick={() => document.activeElement.blur()}
                title={link.title}
                active={
                  location.pathname.endsWith('/') && location.pathname.length > 1
                    ? location.pathname === `${link.href}/`
                    : location.pathname === link.href
                }
              >
                {link.title}
              </SuperLink>
            </li>
          ))}
        </LinksUl>
      </StyledContainer>
      <br />
      <StyledContainer>
        <div>
          <CopyrightP>
            Clínica da Boavista
            <br />
            Todos os Direitos Reservados © {new Date().getFullYear()}
          </CopyrightP>
          <LegalUl>
            {FOOTER_LEGAL_LINKS.map(link => (
              <li key={link.href}>
                <Link href={link.href} title={link.tooltip}>
                  {link.title}
                </Link>
              </li>
            ))}
          </LegalUl>
        </div>
        <div>
          <PaymentsImg
            src="/images/payments.png"
            alt="Métodos de Pagamento"
            title="Métodos de Pagamento"
          />
          <SocialUl>
            {siteMetadata.socialLinks.map(link => (
              <li key={link.title}>
                <SocialIcon title={link.title} url={link.url} />
              </li>
            ))}
          </SocialUl>
        </div>
      </StyledContainer>
    </FooterElement>
  );
};

export default DesktopFooter;
