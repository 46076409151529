import React, { useState, useEffect } from 'react';

import { FaLongArrowAltUp } from 'react-icons/fa';

import styled from '@emotion/styled';

import theme from '@zd/theme';

const WrapperDiv = styled.div`
  display: none;
  position: fixed;
  bottom: 1.8rem;
  right: 1.8rem;
  font-size: 0.75rem;

  > button {
    border: 0;
    background: none;
    outline: none;
  }

  @media (min-width: 1375px) {
    display: block;
  }
`;

const ButtonTextSpan = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 0.5rem;
`;

const StyledFaLongArrowAltUp = styled(FaLongArrowAltUp)`
  color: ${theme.colors.primaryBlue};
`;

const BackToTop = () => {
  const [scrollUp, setScrollUp] = useState();
  const myOffset = 300;

  useEffect(() => {
    setScrollUp(window.pageYOffset);
    const handleWindowScroll = () => setScrollUp(window.pageYOffset);
    window.addEventListener('scroll', handleWindowScroll);

    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, []);

  return scrollUp > myOffset ? (
    <WrapperDiv>
      <button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        <StyledFaLongArrowAltUp />
        <ButtonTextSpan>Topo</ButtonTextSpan>
        <StyledFaLongArrowAltUp />
      </button>
    </WrapperDiv>
  ) : null;
};

export default BackToTop;
