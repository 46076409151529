// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contactos-js": () => import("./../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marcar-consulta-js": () => import("./../src/pages/marcar-consulta.js" /* webpackChunkName: "component---src-pages-marcar-consulta-js" */),
  "component---src-pages-newsletter-js": () => import("./../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-perguntas-frequentes-js": () => import("./../src/pages/perguntas-frequentes.js" /* webpackChunkName: "component---src-pages-perguntas-frequentes-js" */),
  "component---src-pages-sobre-js": () => import("./../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-legal-page-js": () => import("./../src/templates/legal-page.js" /* webpackChunkName: "component---src-templates-legal-page-js" */)
}

