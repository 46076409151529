import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import theme from '@zd/theme';

const WrapperDiv = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  ${theme.medias.extraSmall} {
    max-width: 1100px;
  }

  ${theme.medias.small} {
    max-width: 1100px;
  }

  ${theme.medias.medium} {
    max-width: 1100px;
  }

  ${theme.medias.large} {
    max-width: 1100px;
  }

  ${theme.medias.extraLarge} {
    max-width: 1100px;
  }
`;

const Container = props => {
  return <WrapperDiv {...props} />;
};
Container.propTypes = {
  children: PropTypes.any,
  fluid: PropTypes.oneOf([true, false, 'sm', 'md', 'lg', 'xl']),
};

Container.defaultProps = {
  fluid: false,
};

export default Container;
