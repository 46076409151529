import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Dropdown from 'react-bootstrap/Dropdown';

import Link from '@zd/components/link';

import theme from '@zd/theme';

const marginBottom = '3px';

const StyledDropdownToggle = styled(Dropdown.Toggle, {
  shouldForwardProp: prop => prop !== 'active',
})`
  position: relative;
  text-decoration: none;
  padding: 0;
  padding-bottom: ${marginBottom};
  margin-bottom: -${marginBottom} !important;
  transition: all 0.1s ease-in-out;

  color: ${theme.colors.darkGrey} !important;
  background-color: transparent !important;
  border-color: transparent !important;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    color: ${theme.colors.primaryBlue} !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;

    &::before {
      width: 100%;
    }
  }

  &::after {
    display: none;
  }

  &::before {
    position: absolute;
    content: '';
    background: ${theme.colors.primaryBlue};
    height: 2px;
    width: 0%;
    bottom: 0;
    border-radius: 2px;
    transition: all 0.27s;
  }

  ${({ active }) => {
    if (active) {
      return css`
        color: ${theme.colors.primaryBlue};

        &::before {
          width: 100%;
        }
      `;
    }
  }}
`;

const StyledDropdownMenu = styled(Dropdown.Menu)``;

const StyledDropdownItem = styled(Dropdown.Item)`
  padding: 0;

  &:hover {
    color: white;
    background-color: ${theme.colors.primaryBlue} !important;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  padding: 0.25rem 1.5rem;

  &:hover {
    color: white;
    background-color: ${theme.colors.primaryBlue} !important;
  }
`;

const SupperLink = props => {
  const { id, title, links } = props;

  return (
    <Dropdown>
      <StyledDropdownToggle id={id}>{title}</StyledDropdownToggle>

      <StyledDropdownMenu>
        {links.map((link, i) => (
          <StyledDropdownItem
            key={i}
            eventKey={`${i + 1}`}
            onClick={() => document.activeElement.blur()}
          >
            <StyledLink href={link.href}>{link.title}</StyledLink>
          </StyledDropdownItem>
        ))}
      </StyledDropdownMenu>
    </Dropdown>
  );
};

SupperLink.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  active: PropTypes.bool,
};

SupperLink.defaultProps = {
  links: [],
  active: false,
};

export default SupperLink;
