/* eslint-disable react/prop-types */
import React from 'react';
import MainLayout from './main';
import EmptyLayout from './empty';

export default ({ children, pageContext }) => {
  if (pageContext.layout === 'empty') {
    return <EmptyLayout>{children}</EmptyLayout>;
  }

  return <MainLayout>{children}</MainLayout>;
};
