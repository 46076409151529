import React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/core';
import styled from '@emotion/styled';

import globalStyles from '@zd/theme/styles';
import Navbar from '@zd/components/navbar';
import Footer from '@zd/components/footer';
import BackToTop from '@zd/components/back-to-top';
import CookieBanner from '@zd/components/cookie-banner';

import theme from '@zd/theme';

const Main = styled.main`
  padding-top: calc(${theme.variables.navbarMobileHeight} + 1rem);
  min-height: 60vh;

  ${theme.medias.gteLarge} {
    padding-top: calc(${theme.variables.navbarDesktopHeight} + 2rem);
  }
`;

const MainLayout = props => {
  const { children } = props;

  return (
    <>
      <Global styles={globalStyles} />
      <Navbar />
      <Main>{children}</Main>
      <Footer />
      <CookieBanner />
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.any,
};

export default MainLayout;
