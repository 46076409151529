import React from 'react';
import styled from '@emotion/styled';

import MobileNavbar from './MobileNavbar';
import DesktopNavbar from './DesktopNavbar';

import theme from '@zd/theme';

const StyledMobileNavbar = styled(MobileNavbar)`
  display: block;

  ${theme.medias.gteLarge} {
    display: none;
  }
`;

const StyledDesktopNavbar = styled(DesktopNavbar)`
  display: none;

  ${theme.medias.gteLarge} {
    display: flex;
  }
`;

const Navbar = () => {
  return (
    <>
      <StyledMobileNavbar />
      <StyledDesktopNavbar />
    </>
  );
};

export default Navbar;
