import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

const Link = props => {
  const { children, href, title, onClick, target, keep, passedRef, ...rest } = props;

  const isExternal =
    !href || href.startsWith('tel') || href.startsWith('mailto') || href.startsWith('http');

  const handleClick = () => {
    if (!keep && !isExternal) {
      window && window.scrollTo(0, 0);
    }

    onClick();
  };

  return isExternal ? (
    <a
      href={href}
      title={title}
      aria-label={title}
      onClick={onClick}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : ''}
      ref={passedRef}
      {...rest}
    >
      {children}
    </a>
  ) : (
    <GatsbyLink
      to={href}
      title={title}
      aria-label={title}
      onClick={handleClick}
      ref={passedRef}
      {...rest}
    >
      {children}
    </GatsbyLink>
  );
};

Link.propTypes = {
  children: PropTypes.any,
  href: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  keep: PropTypes.bool,
  passedRef: PropTypes.any,
};

Link.defaultProps = {
  onClick: () => undefined,
  target: '_self',
  keep: false,
};

export default Link;
